.loyalty {
  width: 980px;
  margin: 60px auto;
}

.loyalty__lead {
  display: block;
  margin: 20px 0 60px;
}

.loyalty__cards {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loyalty__card {
  width: 185px;
  height: 220px;
}

.loyalty__integration {
  margin-top: 60px;
  display: flex;
  align-items: center;
}

.loyalty__integration-text {
  margin-right: 10px;
}
