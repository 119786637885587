.slider {
  width: 980px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slider__items {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.slider__item {
  width: 520px;
  margin-bottom: 20px;
  padding: 20px 40px;
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.2s ease;
  border: 1px solid;
  border-color: transparent;
}

.slider__item:last-child {
  margin-bottom: 0;
}

.slider__item:hover {
  background: #fff;
  border: 1px solid #595152;
  box-shadow: 5px 5px 0 0 #E5E5E5;
}

.slider__item_active {
  background: #fff;
  border: 1px solid #595152;
  box-shadow: 5px 5px 0 0 #E5E5E5;
}

.slider__dot {
  margin: 3px 10px 0 0;
  min-width: 8px;
  height: 8px;
  background: #FFCC00;
  border-radius: 100px;
  border: 2px solid #595152;
}

.slider__image-wrapper {
  position: relative;
  width: 100%;
  height: 615px;
}

.slider__image {
  position: absolute;
  top: 0;
  left: 0;
}

.alert-enter {
  opacity: 0;
}
.alert-enter-active {
  opacity: 1;
  transition: opacity .3s linear;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transition: opacity .3s linear;
}
