.reserve {
  width: 980px;
  margin: 0 auto 60px;
  box-sizing: border-box;
}

.reserve__heads {
  width: 980px;
  margin: 0 auto 80px;
}

.reserve__lead {
  display: block;
  margin-top: 20px;
}

.reserve__items {
  margin-bottom: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.reserve__item {
  width: 48%;
  margin-bottom: 40px;
  position: relative;
  height: auto;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid #595152;
  background: #fff;
  border-radius: 5px;
  box-shadow: 5px 5px 0 0 #E5E5E5;
}

.reserve__item-image {
  margin-bottom: 10px;
}