* {
  font-family: "Exo 2", sans-serif;
  color: #101622;
}

body {
  margin: 0;
  padding: 0;
}

/** _____Typography_____ **/

h1 {
  margin: 0;
  font-size: 80px;
  line-height: 80px;
  font-weight: 900;
  letter-spacing: 1px;
}

h2 {
  margin: 0;
  font-size: 40px;
  line-height: 50px;
  font-weight: 500;
  letter-spacing: 1px;
}

h3 {
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  letter-spacing: .5px;
}

p {
  margin: 0;
  font-size: 15px;
  line-height: 25px;
  font-weight: 500;
}

.subline {
  margin: 0;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
}

.small-text {
  margin: 0;
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
}

a {
  text-decoration: none;
}

.lead {
  font-size: 24px;
  line-height: 36px;
  font-weight: 500;
  letter-spacing: 1px;
}

.lead_small {
  font-size: 18px;
  line-height: 25px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

/** _____UI_____ **/

button {
  height: 45px;
  margin: 0 auto;
  padding: 0 30px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  border-radius: 100px;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: all 0.2s ease;
}

.button_first {
  color: #595152;
  background-color: #ffcc00;
}

.button_first:hover {
  background-color: #eebe00;
}

.button_second {
  color: #fff;
  background-color: #595152;
}

.button_second:hover {
  background-color: #433c3c;
}

.button_third {
  color: #fff;
  background-color: #80b600;
}

.button_third:hover {
  background-color: #6d9b00;
}

.card {
  position: relative;
  max-width: 100%;
  height: auto;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #595152;
  background: #fff;
  border-radius: 5px;
  box-shadow: 5px 5px 0 0 #E5E5E5;
}

.card__heads_invisible, .card__image_invisible, .card__over_invisible {
  opacity: 0;
}

.card__image {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.card__heads {
  min-height: 30%;
  transition: all 0.2s ease;
  text-align: center;
}

.card__over {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  transition: all 0.2s ease;
  background: #ffcc00;
}

label {
  margin: 0 0 15px;
  font-size: 15px;
  line-height: 15px;
  font-weight: 500;
}

input {
  width: 100%;
  height: 50px;
  padding: 15px 15px 18px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #E5E5E5;
  outline: 0;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
}

textarea {
  width: 100%;
  height: 100px;
  padding: 15px 15px 18px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #E5E5E5;
  outline: 0;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
}

.error {
  border: 1px solid red;
}