.security {
  width: 980px;
  margin: 0 auto;
}

.security__headline {
  margin-bottom: 60px;
  text-align: center;
}

.security__items {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.security__item {
  width: 300px;
  height: 160px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.security__heads {
  margin-top: 20px;
}