.contacts {
  width: 980px;
  margin: 0 auto;
}

.contacts__headline {
  margin-bottom: 60px;
  text-align: center;
}

.contacts__wrapper {
  /* background: url("../../Assets/Contacts/shape.svg") no-repeat 0 0; */
  background-size: 980px 660px;
}

.contacts__form {
  position: relative;
  width: 600px;
  height: 600px;
  margin: 0 auto;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  border: 1px solid #595152;
  background: #fff;
  border-radius: 5px;
  box-shadow: 5px 5px 0 0 #E5E5E5;
}

.contacts__form-item {
  width: 48%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.contacts__form-item_full {
  width: 100%;
}

.contacts__footer {
  margin: 40px auto;
  display: block;
  text-align: center;
}

.contacts__success {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background: #fff;
}

.contact__success-head, .contact__success-lead {
  display: block;
  text-align: center;
}