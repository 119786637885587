.mobileapps {
  width: 100%;
  margin: 0 auto;
  padding: 60px 0 0;
  box-sizing: border-box;
}

.mobileapps__heads {
  width: 980px;
  margin: 0 auto 80px;
  text-align: center;
}

.mobileapps__lead {
  display: block;
  margin-top: 20px;
}