.crm {
  width: 980px;
  margin: 0 auto;
}

.crm__headline {
  text-align: center;
}

.crm__subline {
  margin: 20px auto 60px;
  display: block;
  text-align: center;
}

.crm__toggles {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.crm__toggle {
  height: 45px;
  margin-right: 10px;
  padding: 0 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #595152;
  border-radius: 100px;
  border: 1px solid #595152;
  background: #fff;
  outline: 0;
  cursor: pointer;
  transition: all 0.2s ease;
}

.crm__toggle:hover, .crm__toggle_active {
  background: #595152;
  color: #fff;
}

.crm__caption-wrapper {
  position: relative;
  height: 40px;
}

.crm__caption {
  position: absolute;
  width: 100%;
  display: block;
  text-align: center;
}

.crm__image-wrapper {
  position: relative;
  height: 747px;
  box-shadow: 0 20px 30px rgba(0, 0, 0, .15);
}

.crm__image {
  position: absolute;
}

.alert-enter {
  opacity: 0;
}
.alert-enter-active {
  opacity: 1;
  transition: opacity .3s linear;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transition: opacity .3s linear;
}
