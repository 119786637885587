.marketing {
  width: 980px;
  margin: 0 auto;
}

.marketing__lead {
  display: block;
  margin: 20px 0 60px;
}

.marketing__cards {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.marketing__card {
  width: 230px;
  height: 220px;
}