.hero {
  width: 100%;
  height: 660px;
  padding: 40px;
  box-sizing: border-box;
  background: #ffcc00;
}

.hero__header {
  max-width: 980px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hero__menu-item {
  margin-right: 20px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  transition: all 0.2s ease;
}

.hero__menu-item:last-child {
  margin-right: 0;
}

.hero__menu-item:hover {
  color: #595152;
}

.hero__content {
  width: 700px;
  height: 480px;
  margin: 50px auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: 480px 480px;
}

.hero__subline,
.hero__headline {
  text-align: center;
}

.hero__headline {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.alert-enter {
  opacity: 0;
}
.alert-enter-active {
  opacity: 1;
  transition: opacity .4s;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transition: opacity .4s;
}

.hero__wrapper {
  position: relative;
  width: 800px;
  height: 260px;
  overflow: hidden;
}