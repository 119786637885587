.delivery {
  width: 100%;
  margin: 0 auto;
  padding: 60px;
  box-sizing: border-box;
  background: #F5F5F5;
}

.delivery__heads {
  width: 980px;
  margin: 0 auto 80px;
}

.delivery__lead {
  display: block;
  margin-top: 20px;
}